import React from "react";
import { Link } from "gatsby";
import NewsletterImage from "../../images/newsletter-image.jpg";

const NewsletterCTA = () => {
  return (
    <div className="newsletter-cta">
      <div className="newsletter-cta__image">
        <img loading="lazy" src={NewsletterImage} alt="Decorative" />
      </div>
      <div className="newsletter-cta__content">
        <div className="newsletter-cta__text">
          <h2>Stay up to date with {process.env.GATSBY_SITE_NAME}</h2>
          <p>
          Introducing Sportaroo: Your essential sports newsletter! Get the the latest news and analysis on all things sport. Subscribe today to get the information you need to keep in the loop.
          </p>
          <Link to="/en/contact" className="button">
            Sign up to newsletter
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsletterCTA;
